import React, { useEffect } from 'react';
import * as Yup from 'yup';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import Heading from '../../../../layout/admin/heading';
import AutoForm from '../../../../form/auto-form';
import { formatBirthdayFromServer } from '../../../../../utils/helpers';
import SEO from '../../../../layout/seo';

const AdminUser = ({ userId }) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/admin/users',
        label: 'Users',
      },
      {
        href: `/app/admin/user${userId ? `/edit?id=${userId}` : ''}`,
        label: `${userId ? 'Update' : 'Create'} User`,
      },
    ]);
  }, [userId]);


  const userForm = {
    type: 'post',
    fetchRoute: userId ? `/admin/user/${userId}` : null,
    fetchErrorRedirect: '/admin/users',
    fetchErrorMessage: 'There was an error while attempting to load the requested user.',
    postRoute: `/admin/user${userId ? `/${userId}` : ''}`,
    successRedirect: '/app/admin/users',
    successNotification: 'The user was saved.',
    fields: [
      {
        name: 'first_name',
        label: 'First name',
        type: 'text',
        required: true,
      },
      {
        name: 'last_name',
        label: 'Last name',
        type: 'text',
        required: true,
      },
      {
        name: 'role_id',
        label: 'Role',
        type: 'select',
        optionsUrl: '/options/roles',
        required: true,
      },
      {
        id: 'email',
        name: 'email',
        label: 'Email',
        type: 'email',
        helpText: 'This will be used to login.',
        required: true,
      },
      {
        name: 'password',
        label: 'Password',
        placeholder: 'Enter a strong password.',
        type: 'password',
        helpText: `Must be at least 6 characters. ${userId ? 'Leave blank to ignore the password and just update the user.' : ''}`,
        validations: () => {
          if (!userId) {
            return Yup.string().min(6, 'Must be at least 6 characters.').required('Password is a required field.');
          } else {
            return Yup.string().min(6, 'Must be at least 6 characters.');
          }
        },
        initialValue: () => {
          return '';
        },
      },
      {
        name: 'phone',
        label: 'Phone',
        type: 'phone',
        helpText: 'Must be a mobile phone.',
      },
      {
        name: 'birthday',
        label: 'Birthday',
        type: 'date',
        helpText: 'Add a birthday for the user.',
        initialValue: (user) => {
          const { birthday_day: day, birthday_month: month, birthday_year: year } = user || {};
          return formatBirthdayFromServer(day, month, year);
        },
      },
    ],
    submitLabel: `${userId ? 'Update' : 'Create'} User`,
  };

  return (
    <AdminContainer>
      <SEO title={`${userId ? 'Update' : 'Create'} User`} />
      <Heading title={`${userId ? 'Update' : 'Create'} User`} />
      <div className="max-w-2xl mx-auto py-12">
        <AutoForm {...userForm} />
      </div>
    </AdminContainer>
  );
}

export default AdminUser;
